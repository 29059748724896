import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'
import HeaderContext from 'context/headerContext'
import { format } from 'date-fns'
import moment from 'moment'
import Loading from 'utils/UI/Loading'
import DefaultTable from 'utils/UI/Tables/DefaultTable'
import CardWrapper from 'utils/UI/Wrappers/CardWrapper'
import { smallNumberFormatter } from 'utils/formatters/currencyFormatters'

import { CashSummaryRequest } from 'axios/requests/movements'
import { errorNotification } from 'utils/UI/Notifications/Notifications'

export default function CashSummaryTable() {
  const { t } = useTranslation(['dashboard', 'common'])
  const { headerState } = useContext(HeaderContext)
  const { startDate, endDate, currentPortfolio } = headerState
  const [rowsData, setRowsData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let mounted = true
    const fetchData = async () => {
      try {
        setLoading(true)
        const result = await CashSummaryRequest({
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
          portfolio_id: currentPortfolio.id
        })
        return result.data
      } catch (err) {
        errorNotification('generalError')
        setLoading(false)
      }
    }

    if (mounted && currentPortfolio) {
      fetchData().then((data) => {
        if (mounted && data) {
          setRowsData(data)
          setLoading(false)
        }
      })
    }

    return () => {
      mounted = false
    }
  }, [currentPortfolio, startDate, endDate])

  const colsData = [
    {
      id: 'concept',
      label: t('Concepto'),
      bold: true,
      minWidth: 200
    },
    {
      id: 'amount_clp',
      label: t('Monto CLP'),
      align: 'right',
      format: (value) => smallNumberFormatter(value)
    },
    {
      id: 'amount_usd',
      label: t('Monto USD'),
      align: 'right',
      format: (value) => smallNumberFormatter(value)
    }
  ]

  const formatSummaryData = (data) => {
    if (!data) return []

    const rows = [
      {
        concept: `${t('Saldo Inicial')} (${moment(data.initial_date).format(
          'DD-MM-YYYY'
        )})`,
        amount_clp: data.CLP.initial_cash,
        amount_usd: data.USD.initial_cash
      },
      {
        concept: t('Compras'),
        amount_clp: data.CLP.buy_transactions_sum,
        amount_usd: data.USD.buy_transactions_sum
      },
      {
        concept: t('Ventas'),
        amount_clp: data.CLP.sell_transactions_sum,
        amount_usd: data.USD.sell_transactions_sum
      },
      {
        concept: t('Comisiones'),
        amount_clp: data.CLP.fee_sum,
        amount_usd: data.USD.fee_sum
      },
      {
        concept: t('Aportes'),
        amount_clp: data.CLP.deposits_sum,
        amount_usd: data.USD.deposits_sum
      },
      {
        concept: t('Rescates'),
        amount_clp: data.CLP.withdrawals_sum,
        amount_usd: data.USD.withdrawals_sum
      },
      {
        concept: t('Transferencias Entrantes'),
        amount_clp: data.CLP.transfers_in_sum,
        amount_usd: data.USD.transfers_in_sum
      },
      {
        concept: t('Transferencias Salientes'),
        amount_clp: data.CLP.transfers_out_sum,
        amount_usd: data.USD.transfers_out_sum
      },
      {
        concept: t('Dividendos e Intereses'),
        amount_clp: data.CLP.dividends_sum,
        amount_usd: data.USD.dividends_sum
      },
      {
        concept: t('Cargos'),
        amount_clp: data.CLP.charges_sum,
        amount_usd: data.USD.charges_sum
      },
      {
        concept: t('Total Movimientos'),
        amount_clp: data.CLP.total_movements,
        amount_usd: data.USD.total_movements,
        bold: true
      },
      {
        concept: `${t('Saldo Final')} (${moment(endDate).format(
          'DD-MM-YYYY'
        )})`,
        amount_clp: data.CLP.final_cash,
        amount_usd: data.USD.final_cash,
        bold: true
      },
      {
        concept: t('Diferencia No Explicada'),
        amount_clp: data.CLP.unexplained_difference,
        amount_usd: data.USD.unexplained_difference,
        bold: true
      }
    ]

    return rows
  }

  return (
    <CardWrapper title={t('Resumen de Movimientos')}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {loading || !rowsData ? (
            <Loading />
          ) : (
            <DefaultTable
              colsData={colsData}
              rowsData={formatSummaryData(rowsData)}
              maxHeight={false}
            />
          )}
        </Grid>
      </Grid>
    </CardWrapper>
  )
}
